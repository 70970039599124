<template>
  <div>
    <foundItemCard
      v-if="showFoundModal && (product || productNotFound)"
      @add-to-cart="addToCart"
      :imgSrc="imgSrc"
      :lastResult="lastResult"
      :productNotFound="productNotFound"
      @scan-again="reinit"
      :product="product"
    />
    <div v-else>
      <section id="container" class="container">
        <div id="interactive" class="viewport" style="position:relative">
          <div class="detectionArea" :style="detectionArea"></div>
          <video
            id="interactive"
            autoplay="true"
            preload="auto"
            muted="true"
          ></video
          ><canvas @click="changeDetectionArea" class="drawingBuffer"></canvas>
        </div>
      </section>
      <scanToolbar
        mini
        @scan="scan"
        @torch="toggleTorch"
        @reinit="reinit"
        @zoom="toggleZoom"
        :scanIsActive="scanIsActive"
      />
    </div>
  </div>
</template>

<script>
import { db } from "../fire.js";
import Quagga from "quagga";
import { mapState } from "vuex";
import foundItemCard from "../components/foundItemCard";
import scanToolbar from "../components/scanToolbar";

export default {
  components: { foundItemCard, scanToolbar },
  data() {
    return {
      showScanner: true,
      codeArray: [],
      capabilities: {},
      scanIsActive: false,
      productNotFound: false,
      zoomedIn: false,
      imgSrc: null,
      canvas: null,
      torchOn: false,
      lastResult: null,
      x: 20,
      y: 20,
      product: null,
      bars: [{ class: "" }],
    };
  },
  async mounted() {
    this.startBarcodeScan();
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      userID: (state) => state.userID,
      operators: (state) => state.operators,
      showFoundModal: (state) => state.showFoundModal,
      cartContent: (state) => state.cartContent,
      products: (state) => state.products,
      operator: (state) => state.operator,
      token: (state) => state.token,
    }),
    detectionArea() {
      return {
        top: "5%",
        right: "10%",
        left: "10%",
        bottom: "5%",
      };
    },
    fastScan: {
      /* By default get() is used */
      get() {
        return this.$store.state.fastScan;
      },
      /* We add a setter */
      set() {
        this.$store.commit("toggleFastScan");
      },
    },
    torchAllwaysOn: {
      /* By default get() is used */
      get() {
        return this.$store.state.torchAllwaysOn;
      },
      /* We add a setter */
      set() {
        this.$store.commit("toggleTorchAllwaysOn");
      },
    },
  },
  beforeDestroy() {
    Quagga.stop();
    this.scanIsActive = false;
    const video = document.querySelector("#video");
    if (video) {
      video.pause();
    }
    Quagga.stop();
  },
  methods: {
    toggleZoom() {
      const self = this;
      var track = Quagga.CameraAccess.getActiveTrack();
      var capabilities = {};
      if (typeof track.getCapabilities === "function") {
        capabilities = track.getCapabilities();
      }
      if (capabilities.zoom) {
        self.zoomedIn = !self.zoomedIn;
        track
          .applyConstraints({
            advanced: [{ zoom: self.zoomedIn ? 1 : 1.5 }],
          })
          .catch((e) => console.log(e));
      }
    },
    toggleTorch(status) {
      const self = this;
      var track = Quagga.CameraAccess.getActiveTrack();
      var capabilities = {};
      if (typeof track.getCapabilities === "function") {
        capabilities = track.getCapabilities();
      }
      if (capabilities.torch) {
        self.torchOn = !self.torchOn;
        track
          .applyConstraints({
            advanced: [{ torch: status ? status : self.torchOn }],
          })
          .catch((e) => console.log(e));
      }
    },
    changeDetectionArea(event) {
      var bounds = event.target.getBoundingClientRect();
      this.x = event.clientX - bounds.left;
      this.y = event.clientY - bounds.top;
    },
    reinit() {
      this.codeArray = [];
      this.showScanner = true;
      this.productNotFound = false;
      this.product = null;
      this.$store.commit("toggleShowFoundModal", false);
      setTimeout(() => {
        this.startBarcodeScan();
      }, 500);
    },
    addToCart(qty) {
      let item = this.product;
      if (!this.currentCaller) {
        this.$root.notify("Krepšelis neaktyvus");
      }
      this.addOrUpdateQty(item, this.userID, qty);
      this.reinit();
    },
    addOrUpdateQty(item, userID, qty) {
      for (let it in this.cartContent) {
        if (!this.cartContent) break;
        if (this.cartContent[it].id == item.id) {
          db.ref(`userCart/${userID}/items/${it}`).update({
            qty: qty,
          });
          return it;
        }
      }
      item.operator = this.operator;
      item.qty = qty;
      db.ref(`userCart/${userID}/user`).set(this.currentCaller);
      db.ref(`userCart/${userID}/items/`).push(item);
    },
    checkCapabilities() {
      var track = Quagga.CameraAccess.getActiveTrack();
      this.$root.track = track;
      var capabilities = {};
      if (typeof track.getCapabilities === "function") {
        capabilities = track.getCapabilities();
      }
      this.capabilities = capabilities;
      console.log("capabilities", capabilities);
    },
    settings(setting) {
      var track = Quagga.CameraAccess.getActiveTrack();
      track.applyConstraints({
        advanced: [{ torch: true }],
      });
      if (track && typeof track.getCapabilities === "function") {
        switch (setting) {
          case "zoom":
            return track.applyConstraints({
              advanced: [{ zoom: parseFloat(1.5) }],
            });
          case "torch":
            return track.applyConstraints({
              advanced: [{ torch: !!this.torchOn }],
            });
        }
      }
    },
    async startBarcodeScan() {
      const self = this;
      const videoDevices = await Quagga.CameraAccess.enumerateVideoDevices();
      console.log("videoDevices", videoDevices);
      Quagga.init(
        {
          focusMode: "continuous",
          inputStream: {
            name: "Live",
            type: "LiveStream",
            size: 1281,
            target: document.querySelector("#interactive"),
            constraints: {
              width: 1920,
              height: 1200,
              deviceId: videoDevices[videoDevices.length - 1].deviceId,
              // facingMode: "environment",
            },
            area: this.detectionArea,
          },
          frequency: 5,
          locator: {
            patchSize: "medium",
            halfSample: true,
          },
          decoder: {
            readers: [
              {
                format: "ean_reader",
                config: {},
              },
              {
                format: "upc_e_reader",
                config: {},
              },
              {
                format: "code_128_reader",
                config: {},
              },
              {
                format: "codabar_reader",
                config: {},
              },
            ],
          },
        },
        function(err) {
          if (err) {
            console.log(err);
            return;
          }
          console.log("Initialization finished. Ready to start");
          Quagga.start();
          self.scanIsActive = true;
          self.checkCapabilities();
          if (self.torchAllwaysOn) {
            self.toggleTorch(true);
          }
          self.replaceStream();
        }
      );
      this.initRegister();
    },
    replaceStream() {
      if (!this.$root.peer) return;
      if (this.$root.peer.streams.length) return;
      const oldTrack = this.$root.peer.streams[0].getTracks()[0];
      const newTrack = Quagga.CameraAccess.getActiveTrack();
      this.$root.peer.replaceTrack(
        oldTrack,
        newTrack,
        this.$root.peer.streams[0]
      );
      this.initMyVideo();
    },
    initMyVideo() {
      const video = document.querySelector("#my-stream-video");
      video.srcObject = this.$root.peer.streams[0];
    },
    stop() {
      Quagga.stop();
    },
    checkIfCodeRepeats(code) {
      if (this.codeArray.length < (this.fastScan ? 4 : 12)) {
        this.codeArray.push(code);
      } else {
        return this.barcodeFoundMostFreq();
      }
    },
    barcodeFoundMostFreq() {
      var mf = 1;
      var m = 0;
      var item;
      for (var i = 0; i < this.codeArray.length; i++) {
        for (var j = i; j < this.codeArray.length; j++) {
          if (this.codeArray[i] == this.codeArray[j]) m++;
          if (mf < m) {
            mf = m;
            item = this.codeArray[i];
          }
        }
        m = 0;
      }
      return item;
    },
    async retrieveProductByCode(code) {
      this.productNotFound = false;
      const result = this.checkIfCodeRepeats(code);
      if (!result) return;
      let found = this.products.find((element) => element.ean == code);
      if (!found) {
        found = this.products.find((element) => element.ean == "0" + code);
      }
      if (!found) {
        found = this.products.find((element) => element.ean == "00" + code);
      }
      const foundWithoutZeroes = this.products.find(
        (element) => element.ean == code.replace(/^0+/, "")
      );
      this.product = foundWithoutZeroes ? foundWithoutZeroes : found;
      this.showScanner = false;
      window.navigator.vibrate([100, 30, 100, 30]);
      if (!found) {
        this.productNotFound = true;
      }
      this.$store.commit("toggleShowFoundModal", true);
      setTimeout(() => {
        Quagga.stop();
      }, 200);
    },
    initRegister() {
      const self = this;
      Quagga.onProcessed(function(result) {
        var drawingCtx = Quagga.canvas.ctx.overlay,
          drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
          if (result.boxes) {
            drawingCtx.clearRect(
              0,
              0,
              parseInt(drawingCanvas.getAttribute("width")),
              parseInt(drawingCanvas.getAttribute("height"))
            );
            result.boxes
              .filter(function(box) {
                return box !== result.box;
              })
              .forEach(function(box) {
                Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                  color: "green",
                  lineWidth: 2,
                });
              });
          }

          if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
              color: "#00F",
              lineWidth: 2,
            });
          }

          if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(
              result.line,
              { x: "x", y: "y" },
              drawingCtx,
              { color: "red", lineWidth: 3 }
            );
          }
        }
      });
      Quagga.onDetected(function(result) {
        if (self.lastResult !== result.codeResult.code) {
          const canvas = Quagga.canvas.dom.image;
          self.imgSrc = canvas.toDataURL();
          self.lastResult = result.codeResult.code;
        }
        self.retrieveProductByCode(result.codeResult.code);
      });
    },
  },
};
</script>

<style lang="scss">
#interactive.viewport > canvas {
  height: 100%;
  max-width: 100%;
}
#interactive {
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg); /* Safari and Chrome */
  -moz-transform: rotateY(0deg); /* Firefox */
}
.detectionArea {
  position: absolute;
  pointer-events: none;
  z-index: 100;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.4);
    top: 0;
  }
  video {
    max-height: 70vh;
  }
}
#scanner-viewport video {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
